<template>
  <div class="index">
    <div class="section" id="section1">
      <img class="banner" src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/mobile/part1-bg.png" alt="">
    </div>
    <div class="section" id="section2">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part2-title.png" alt="" class="title">
      <div class="subTitle">可批量快速产出，适应各个平台，避开AI检测设备</div>
      <div class="tab">
        <template 
        v-for="(item) in data.tabList" 
        :key="item.value">
          <div 
            :class="{'tabItem':true,'selectd':data.tab==item.value}"
            @click="changeTab(item)">
            {{item.text}}
          </div>
        </template>
      </div>
      <div v-if="data.currentItem" class="imgList" ref="imgRef">
        <img class="model" :src="data.currentItem.model" alt="">
        <div class="arrow">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/rightBig.png" alt="">
        </div>
        <template v-for="(item,index) in  data.currentItem.list" :key="index">
          <img :src="item" alt="" class="product">
        </template>
      </div>
      <div class="arrowBtn">
        <div class="title1">企业定制模型</div>
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/rightSmall.png" alt="">
        <div class="title2">公有大模型</div>
      </div>
    </div>
    <div class="section" id="section3">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/mobile/part3-bg.png" alt="" class="bg">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-title.png" alt="" class="title">
      <div class="text text1">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-1.png" alt="">
        <div class="desc" style="text-align:left">
          普通员工学习AIGC需要 <br/>
          一定的时间成本且不能快<br/>
          速满足企业批量化产出的<br/>
          需求产出质量无法控制
        </div>
      </div>
      <div class="text text2">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-2.png" alt="">
        <div class="desc">
          少量原始素材图片即可<br/>
            批量化输出同一产品输出<br/>
            300张图仅需3-5天
        </div>
      </div>
      <div class="text text3">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-3.png" alt="">
        <div class="desc">
          可为企业丰富所有品线<br/>
          的模型库训练质量可控<br/>
          拓展性强模型可无限复用
        </div>
      </div>
      <div class="text text4">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part3-4.png" alt="">
        <div class="desc">
          无需模特、布景、商拍、<br/>
          达人样货等成本<br/>
          出图成本降低80%
        </div>
      </div>

    </div>
    <div class="section" id="section4">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/mobile/part4-bg.png" alt="" class="bg">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-title.png" alt="" class="title">
      <div class="subtitle">5步专业化训练，输出更符合企业需求的智能模型</div>
      <div class="step">
        <template 
          v-for="(item,index) in data.stepList" 
          :key="index">
          <div class="stepItem">
            <div class="icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="line">
              <div class="mask" v-if="index==0"></div>
              <div class="mask1" v-if="index==4"></div>
            </div>
            <div class="content">
              <div class="title2">{{item.title}}</div>
              <div class="desc" v-html="item.desc"></div>
            </div>
          </div>
        </template>
        
      </div>
    </div>
    <div class="section" id="section5">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/mobile/part5-bg.png" alt="" class="bg">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-title.png" alt="" class="title">
      <div class="subtitle">
        最少训练数据，训练数据独立存储训练结果直接输出，独立部署企业云盘
      </div>
      <div class="wrap">
        <div class="text text1">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-1.png" alt="">
          <div class="content">
            <div class="ctitle">数据安全</div>
            <div class="text">
              可实现内外网隔离<br/>
              更好的控制和保护数据的安全<br/>
              客户数据、企业内部信息、企业资源<br/>
              全部存储在企业自有服务器中
            </div>
          </div>
        </div>
        <div class="text text3">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-3.png" alt="">
          <div class="content">
            <div class="ctitle">高可控性</div>
            <div class="text">
              控制权掌握在企业自己手中<br/>
              企业可以自行管理控制调整资源<br/>
              也可以按需自己升级和维护模型
            </div>
          </div>
        </div>
        <div class="mainTitle">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-mainTitle.png" alt="">
        </div>
        <div class="text text2" >
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-2.png" alt="">
          <div class="content" >
            <div class="ctitle">定制化程度高</div>
            <div class="text">
              对企业适应性强<br/>
              能满足不同企业的实际应用需求<br/>
              还可以集成到各种应用程序和服务中
            </div>
          </div>
        </div>
        <div class="text text4">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part5-4.png" alt="">
          <div class="content">
            <div class="ctitle">拓展性强</div>
            <div class="text">
              支持企业自主训练<br/>
              自己创建应用适合企业需求的应用<br/>
              并不断训练达到企业要求<br/>
              且资料私密性强
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="section6">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/mobile/part6-bg.png" alt="" class="bg">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part6-title.png" alt="" class="title">
      <div class="logoList">
        <template v-for="item in 24" :key="item">
          <div class="logoItem">
            <img :src="'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/logo'+item+'.png'" alt="">
          </div>
        </template>
        
      </div>
      <div class="footer">
        <div class="logo">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/logoTwo.png" alt="">
        </div>
        <div class="wrap">
          <div class="content">
            <div class="ctitle">解决方案</div>
            <div class="row">企业AIGC落地服务</div>
            <div class="row">数字营销整合方案</div>
            <div class="row">数字藏品创新营销服务</div>
          </div>
          <div class="content">
            <div class="ctitle">联系我们</div>
            <div class="row" @click="callPhone">TEL: 17174371777</div>
            <div class="row">邮箱：inspire@inspire.com</div>
          </div>
        </div>
     
        <div class="qrcode">
          <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/code.png" alt="">
        </div>
        <div class="copyRight">
          沪ICP备2022008431号-1 上海蕴元信息科技有限公司
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
 import{reactive,onMounted,ref }from "vue";
 const imgRef=ref(null);
 let data=reactive({
  tabList:[
    {
      text:'商拍供图',
      value:1
    },
    {
      text:'达人带货',
      value:2
    }, {
      text:'社媒投放',
      value:3
    }, {
      text:'电商首焦',
      value:4
    }, {
      text:'营销内容',
      value:5
    }, {
      text:'人像摄影',
      value:6
    }
  ],//导航列表
  tab:1,//当前导航
  stepList:[
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon1.png',
      title:'产品/IP',
      desc:'企业原始产品/IP数据'
    },
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon2.png',
      title:'模型参数训练',
      desc:'Stable Diffusion系列<br/> SDXL系列、ChatGLM系列 <br/>LLaMa系列、Baichuan系列'
    },
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon3.png',
      title:'模型效果测评',
      desc:'将模型部署到相应位置'
    },
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon4.png',
      title:'模型部署上线',
      desc:'测评训练之后的各项参数'
    },
    {
      icon:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/part4-icon5.png',
      title:'平台应用API接口',
      desc:'支持企业自建应用<br/>SAAS平台，API接口接入'
    }
  ],
  currentIndex:0,//当前下标
  currentItem:null,//当前图片
 })
 let imgList=ref([
  {
    model:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/model.png',
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/commercial/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/expert/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/social/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/online/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/market/4.png"
    ]
  },
  {
    model:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/model.png",
    list:[
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/1.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/2.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/3.png",
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/portrait/4.png"
    ]
  }
 ])
 //切换tab
 const changeTab=(item)=>{
  data.tab=item.value;
  data.currentIndex=item.value-1;
  data.currentItem=imgList.value[data.currentIndex];
  imgRef.value.scrollLeft =0;
 }
 //拨打电话
 const callPhone=()=>{
  window.location.href = `tel:17174371777`
 }
  onMounted(()=>{
    data.currentItem=imgList.value[data.currentIndex];
  })
</script>

<style lang="scss" scoped>
  .index{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .section{
      font-size: 0;
    }
    #section1{
      height: auto;
      position: relative;
      padding-top: 0.79rem;
      .banner{
        width:100%;
      }
    }
    #section2{
      padding-top: 0.6rem;
      padding-left: 0.26rem;
      box-sizing: border-box;
      .title{
        height: 0.44rem;
      }
      .subTitle{
        font-family: Source Han Sans CN light;
        font-weight: 300;
        font-size:0.14rem;
        color: #000000;
        margin-top: 0.15rem;
      }
      .tab{
        height: 0.4rem;
        overflow-x: auto;
        margin-top: 0.4rem;
        white-space: nowrap;
        padding-bottom: 8px;
        .tabItem{
          display: inline-block;
          width: 0.92rem;
          height: 0.3rem;
          border-radius: 0.15rem;
          text-align: center;
          line-height: 0.3rem;
          margin-right: 0.08rem;
          border: 2px solid #696969;
          font-family: Source Han Sans CN;
          font-weight: 300;
          font-size: 0.12rem;
          color: #070707;
          cursor: pointer;
          letter-spacing: 4px;
        }
        .selectd{
          border: 2px solid #2b73db;
          color: #fff;
          background: linear-gradient(90deg,#69a7f4,#526df2);
        }
      }
      .imgList{
        overflow-x: auto;
        margin-top: 0.5rem;
        white-space: nowrap;
        font-size: 0;
        .model{
         height: 3.4rem;
        }
        .arrow{
          display: inline-flex;
          width: 0.18rem;
          height: 3.5rem;
          margin-left: 0.1rem;
          margin-right: 0.1rem;
          vertical-align: top; /* 确保顶部对齐 */
          align-items: center;
          img{
            width: 0.18rem;
            height: 0.4rem;
          }
        }
        .product{
          height: 3.4rem;
          margin-right: 0.15rem;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .arrowBtn{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0;
        padding-right:0.26rem;
        box-sizing: border-box;
        margin-top: 0.3rem;
        cursor: pointer;
        .title1{
          flex: 1;
          width: 0;
          text-align: left;
          font-family: Source Han Sans CN light;
          font-weight: 300;
          font-size: 0.14rem;
          color: #232323;
        }
        .title2{
          padding-left: 0.28rem;
          font-family: Source Han Sans CN light;
          font-weight: 300;
          font-size: 0.14rem;
          color: #232323;
        }
        img{
          height: 0.1rem;
        }
      }
    }
    #section3{
      height: 8rem;
      position: relative;
      padding-top: 0.6rem;
      padding-left: 0.26rem;
      box-sizing: border-box;
      .bg{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .title{
        position: relative;
        width: 2.83rem;
        z-index: 1;
      }
      .text{
        text-align: center;
      }
      .text1{
        position: absolute;
        left: 0.4rem;
        top: 2.66rem;
        img{
          width: 1rem;
        }
      }
      .text2{
        position: absolute;
        right: 0.4rem;
        top: 2.97rem;
        font-size: 0;
        img{
          width: 1rem;
        }
      }
      .text3{
        position: absolute;
        left: 0.3rem;
        top: 5.18rem;
        font-size: 0;
        img{
          width: 1rem;
        }
      }
      .text4{
        position: absolute;
        right: 0.4rem;
        top: 5.54rem;
        font-size: 0;
        img{
          width: 1rem;
        }
      }
      .desc{
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 0.12rem;
        color: #000000;
        line-height: 0.15rem;
        margin-top: 0.12rem;
        text-align: center;
      }
    }
    #section4{
      position: relative;
      padding-top: 0.5rem;
      padding-left: 0.26rem;
      padding-bottom: 0.6rem;
      box-sizing: border-box;
      .bg{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .title{
        position: relative;
        width: 1.83rem;
        z-index: 1;
      }
      .subtitle{
        position: relative;
        z-index: 1;
        font-family: 'Source Han Sans CN light';
        font-weight: 300;
        font-size: 0.14rem;
        color: #000000;
        margin-top: 0.15rem;
      }
      .step{
        position: relative;
        z-index: 1;
        width: 100%;
        margin-top: 0.4rem;
        .stepItem{
          height: 1.2rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          .icon{
            width: 0.8rem;
            img{
              width: 100%;
            }
          }
          .line{
            height: 100%;
            width: 2px;
            background: none;
            position: relative;
            margin-left: 0.2rem;
            margin-right: 0.3rem;
            .mask{
              width:4px;
              height:0.5rem;
              position: absolute;
              top: 0;
              left: -1px;
              background: linear-gradient(180deg,#F8FAFD,transparent);
            }
            .mask1{
              width:4px;
              height:0.5rem;
              position: absolute;
              bottom: 0;
              right: -1px;
              background: linear-gradient(0deg,#E4E5FC,transparent);
            }
            &::before{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(to bottom, transparent 0%, transparent 50%, #66A1F3 50%,#66A1F3 100%);
              background-size: 2px 20px;
              background-repeat: repeat-y;
            }
            &::after{
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              width: 0.16rem;
              height: 0.16rem;
              border-radius: 50%;
              background: #5837E4;
              z-index: 1;
            }
          }
          .title2{
            font-family: 'Source Han Sans CN bold';
            font-weight: bold;
            font-size: 0.18rem;
            color: #63A1F4;
            margin-bottom: 0.1rem;
          }
          .desc{
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 0.12rem;
            color: #000000;
            line-height: 0.15rem;
            text-align: center;
          }
        }
      }
    }
    #section5{
      position: relative;
      padding-top: 0.6rem;
      padding-left: 0.26rem;
      box-sizing: border-box;
      .bg{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .title{
        position: relative;
        width: 1.88rem;
        z-index: 1;
      }
      .subtitle{
        position: relative;
        z-index: 1;
        font-family: 'Source Han Sans CN light';
        font-weight: 300;
        font-size: 0.14rem;
        color: #000000;
        margin-top: 0.16rem;
      }
      .wrap{
        position: relative;
        z-index: 1;
        margin-top: 0.7rem;
        width:100%;
        padding-bottom: 0.5rem;
        .mainTitle{
          height:0.24rem;
          font-size: 0;
          margin-bottom: 0.3rem;
          img{
            height: 100%;
          }
        }
        .text{
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          img{
            height: 0.5rem;
            margin-right: 0.12rem;
          }
          .content{
            text-align: left;
            .ctitle{
              font-family: 'Source Han Sans CN bold';
              font-weight: bold;
              font-size: 0.18rem;
              color: #000;
              margin-bottom: 0.12rem;
              line-height: 1;
            }
            .text{
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 0.12rem;
              color: #000000;
              line-height: 0.15rem;
            }
         
          }
  
        }
        .text1{
          margin-left: 0.3rem;
          margin-bottom:0.4rem;
        }
        .text2{
          margin-left: 1.28rem;
          margin-bottom:0.4rem;
        }
        .text3{
          margin-left: 1.3rem;
          margin-bottom: 1px;
        }
        .text4{
          margin-left: 0.2rem;
          
        }
      }
    }
    #section6{
      position: relative;
      padding: 0.6rem 0.26rem 0;
      box-sizing: border-box;
      .bg{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .title{
        position: relative;
        height: 0.44rem;
        z-index: 1;
    
      }
      .logoList{
        position: relative;
        z-index: 1;
        width: 100%;
        margin-top: 0.4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .logoItem{
          width: 0.64rem;
          height: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.12rem;
          margin-bottom: 0.4rem;
          cursor: pointer;
          &:nth-child(4n){
            margin-right: 0;
          }
          img{
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
      .footer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0.3rem;
        .logo{
          height: 0.52rem;
          font-size: 0;
          margin-bottom:0.26rem;
          img{
            height: 100%;
          }
        }
        .wrap{
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .content{
          text-align: center;
          &:first-child{
            margin-right: 0.3rem;
          }
          .ctitle{
            font-family: Source Han Sans CN bold;
            font-weight: bold;
            font-size: 0.16rem;
            color: #3B125E;
            line-height: 1;
            margin-bottom: 0.12rem;
          }
          .row{
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 0.12rem;
            color: #000000;
            margin-top: 0.08rem;
            line-height: 1;
          }
        }
        .qrcode{
          text-align: center;
          height: 1.4rem;
          font-size: 0;
          margin-top: 0.2rem;
          img{
            height: 100%;
          }
        }
        .copyRight{
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          color: #595959;
          font-size: 0.12rem;
        }
      }
    }
  }
</style>